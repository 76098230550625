$primary: #f1da36;

/* MAIN COLOR */

$secondary: #066dab;

/* SECONDARY COLOR */

$blk: #333;

/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */

$wht: #fafafa;
$border-radius: 0px;

/* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */

$footerLinks: #fafafa;
html,
body {
    height: 100%;
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
}

a,
p,
ol,
ul {
    font-family: 'Vollkorn', serif;
}

h1,
h2,
h3,
h4 {
    font-family: 'Alex Brush', cursive;
}

.navbar-default {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    z-index: 10000;
    transition: all 0.2s ease-in-out;
    height: auto;
    background-color: transparent;
    text-align: center;
    line-height: 40px;
}

.navbar-default.active {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#c5deea+0,8abbd7+31,066dab+100;Web+2.0+Blue+3D+%231 */
    background: #c5deea;
    /* Old browsers */
    background: -moz-linear-gradient(top, #c5deea 0%, #8abbd7 31%, #066dab 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #c5deea 0%, #8abbd7 31%, #066dab 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #c5deea 0%, #8abbd7 31%, #066dab 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
}

.navbar-default2 {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#c5deea+0,8abbd7+31,066dab+100;Web+2.0+Blue+3D+%231 */
    background: #c5deea;
    /* Old browsers */
    background: -moz-linear-gradient(top, #c5deea 0%, #8abbd7 31%, #066dab 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #c5deea 0%, #8abbd7 31%, #066dab 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #c5deea 0%, #8abbd7 31%, #066dab 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    z-index: 10000;
    transition: all 0.2s ease-in-out;
    height: auto;
    background-color: transparent;
    text-align: center;
    line-height: 40px;
}

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}


/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    .navbar-nav {
        >li>a {
            text-align: center;
            margin-top: 22px;
            display: flex;
            align-items: center;
            color: $wht;
            @media (max-width: 767px) {
                margin-top: 0;
                display: inline-block;
            }
            &:hover {
                background: transparent;
                text-decoration: underline;
                color: $wht !important;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
            &:focus,
            &:active {
                background: transparent;
                color: $wht;
                outline: 0;
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
    font-size: 2em;
    @media (max-width: 1024px) {
        font-size: 1.5em;
    }
}

.navbar-toggle {
    margin: 15px 15px 8px 0px;
    border: 1px solid #fff;
    border-radius: 4px;
}

.icon-bar {
    background: #fff !important;
}

.navbar-collapse {
    @media (max-width: 767px) {
        background: rgba(31, 31, 31, .95);
    }
}


/** LOGIN FORM **/

@mixin btn {
    border: 1px solid $secondary;
    border-radius: $border-radius;
    background: $secondary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        box-shadow: 0px 0px 3px lighten($blk, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $secondary;
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: $secondary;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 75%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $secondary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: $secondary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 250px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 175px;
    }
    .navbar-header a {
        float: left;
    }
}

.top-pad {
    padding: 8em 0px;
    background: white;
}


/* ========= BUTTONS ============ */

.btn-download,
.btn-download:focus,
.btn-download:active,
.btn-download:visited {
    background-color: $secondary;
    border-color: $wht;
    color: $wht;
    padding: 10px 20px;
    font-size: 19px;
    border-radius: 12px;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.btn-download:hover {
    background-color: $primary;
    color: $wht;
}

.btn-download {
    margin-bottom: 15px !important;
    display: block;
    margin: 0 auto;
    max-width: 175px;
    margin-top: 50px;
    @media (max-width: 767px) {
        margin-top: 10px;
    }
    @media (max-width: 1024px) {
        margin-top: 10px;
    }
}


/* ============ HEADER ============ */

.header {
    background: url('../img/banner.jpg');
    background-repeat: no-repeat;
    background-position: 50% 10%;
    background-size: cover;
}

.header-overlay {
    padding: 350px 0px;
    background: rgba(31, 31, 31, .65);
    @media (max-width: 1024px) {
        padding: 175px 0px;
    }
    @media (max-width: 767px) {
        padding: 125px 0px;
    }
}

.header h1 {
    color: $wht;
    text-align: center;
    display: block;
    margin: 0 0 20px 0;
    font-size: 6em;
    line-height: 72px;
    font-weight: 400;
    color: #fff;
    text-transform: capitalize;
    @media (max-width: 767px) {
        font-size: 5em;
    }
}

.header p {
    color: $wht;
    text-align: center;
    display: block;
    margin: 0 0 20px 0;
    font-size: 35px;
    line-height: 40px;
    font-weight: 400;
    color: #fff;
    text-transform: capitalize;
    @media (max-width: 1024px) {
        font-size: 1.5em;
        letter-spacing: 1px;
    }
    @media (max-width: 767px) {
        font-size: 1.5em;
    }
}


/* ============== SECTION ONE =============== */

.section-one {
    padding: 50px 0px;
}

.section-one span {
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    line-height: 16px;
    font-weight: 600;
    display: block;
    padding: 0 0 15px 0;
    text-align: center;
    color: #fca600
}

.section-one h1 {
    color: $wht;
    text-align: center;
    display: block;
    margin: 0 0 20px 0;
    font-size: 4em;
    line-height: 72px;
    font-weight: 400;
    color: $blk;
    text-transform: capitalize;
    font-family: 'Vollkorn', serif;
}

.section-one p {
    color: $blk;
    text-align: center;
    display: block;
    margin: 0 50px 0 50px;
    font-size: 1.5em;
    line-height: 40px;
    letter-spacing: 1px;
    font-weight: 400;
    margin-bottom: 45px;
    @media (max-width: 767px) {
        font-size: 1.5em;
        margin: 20px 0;
        line-height: 30px;
    }
}

.section-one img {
    width: 100%;
    display: block;
    margin: 0px auto;
    max-width: 650px;
    margin-top: 25px;
}

span.blockquote {
    font-size: 1.25em;
    line-height: 30px;
    text-align: left;
    margin-top: 25px !important;
}

.section-one .name {
    text-align: right;
}


/* ============ SECTION-TWO ============== */

.section-two {
    background: url('../img/lower-banner.jpg');
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
}

.section-two-overlay {
    padding: 200px 0px;
    background: rgba(31, 31, 31, .5);
    @media (max-width: 1024px) {
        padding: 25px 0px;
    }
}

.section-two h3 {
    color: $wht;
    font-size: 2.65em;
    text-align: center;
    margin-top: 0px;
}

.section-two p {
    color: $blk;
    font-size: 1.25em;
}

.section-two .middle {
    padding: 30px;
    border-radius: 3px;
    background: rgba(255, 255, 255, .5);
    min-height: 244px;
    @media (max-width: 1024px) {
        margin-top: 25px;
    }
}

.section-two img {
    @media (max-width: 1024px) {
        margin-top: 25px;
    }
}


/* ============ SECTION-THREE ============== */

.section-three {
    padding: 75px 0px;
    text-align: left;
    @media (max-width: 767px) {
        text-align: center;
    }
}

.section-three span {
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    line-height: 16px;
    font-weight: 600;
    display: block;
    padding: 0 0 15px 0;
    text-align: center;
    color: #fca600
}

.section-three h1 {
    color: $wht;
    text-align: center;
    display: block;
    margin: 0 0 20px 0;
    font-size: 4em;
    line-height: 72px;
    font-weight: 400;
    color: $blk;
    text-transform: capitalize;
    font-family: 'Vollkorn', serif;
    @media (max-width: 767px) {
        font-size: 3em;
        line-height: 45px;
    }
}

.section-three h2 {
    color: $blk;
    font-size: 3.5em;
}

.section-three p {
    color: $blk;
    display: block;
    font-size: 1.25em;
    line-height: 25px;
    letter-spacing: 1px;
    font-weight: 400;
    margin-bottom: 45px;
}


/* ============ SECTION-FOUR ============== */

.section-four {
    background: url('../img/lower-banner-cta.jpg');
    background-repeat: no-repeat;
    background-position: 50% 10%;
    background-size: cover;
}

.section-four-overlay {
    padding: 300px 0px;
    background: rgba(31, 31, 31, .65);
    @media (max-width: 767px) {
        padding: 25px 0px;
    }
}

.section-four h1 {
    color: $wht;
    font-size: 4em;
}

.section-four p {
    color: $wht;
    font-size: 1.5em;
}

.section-four .cta-btn {
    margin-top: 75px;
}


/* ============= TERMS & PRIVACY =============== */

.terms h2,
h3 {
    text-transform: lowercase;
    font-size: 3em;
}

.privacy h2,
h3 {
    text-transform: lowercase;
    font-size: 3em;
}